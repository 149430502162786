import "./ClientFeedback.css";
import { useEffect, useRef, useState, useCallback } from "react";
import Trust from "./ClientFeedbackImages/dark-trustpilot.webp";
import Google from "./ClientFeedbackImages/dark-google.webp";
import D from "./ClientFeedbackImages/dennis.webp";
import D_L from "./ClientFeedbackImages/dennis-logo.svg";
import V from "./ClientFeedbackImages/vetcare.webp";
import V_L from "./ClientFeedbackImages/vetcare-logo.webp";
import I from "./ClientFeedbackImages/imr.webp";
import I_L from "./ClientFeedbackImages/imr-logo.webp";
import P from "./ClientFeedbackImages/prestige-feadback.webp";
import P_L from "./ClientFeedbackImages/Prestige-logo.svg";
import T from "./ClientFeedbackImages/tough-enough-feedback.webp";
import T_L from "./ClientFeedbackImages/tough-enough-logo.svg";
import P2 from "./ClientFeedbackImages/Percys-Feedback.webp";
import P2_L from "./ClientFeedbackImages/Percys-Logo.svg";
import BackwardsCursor from "./ClientFeedbackImages/feedback-cursor.webp";
import ForwardsCursor from "../CollectionPosts/CollectionPostsImages/blog-cursor.webp";

let index;
let length;
let direction = "backwards";
const cursorImg = {
  forwards: "url(" + ForwardsCursor + ") 56.5 56.5, auto",
  backwards: "url(" + BackwardsCursor + ") 56.5 56.5, auto",
};

function ClientFeedback() {
  const [cursor, setCursor] = useState(cursorImg.backwards);
  // Find image and text containers
  const iRef = useRef(null);
  const tRef = useRef(null);
  const cRef = useRef(null);
  const nav = useRef();

  // Check for touchscreen
  const isTouchDevice = useCallback(() => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }, []);

  // Set last elements to active on first load
  useEffect(() => {
    const setActive = iRef.current.querySelectorAll("img").length - 1;
    iRef.current.querySelectorAll("img")[setActive].classList.add("active");
    tRef.current.querySelectorAll("div")[setActive].classList.add("active");
    cRef.current.style.cursor = cursorImg.backwards;
    index = setActive;
    length = setActive;
    if (isTouchDevice()) {
      iRef.current
        .querySelectorAll("img")
        [setActive].classList.remove("active");
      tRef.current
        .querySelectorAll("div")
        [setActive].classList.remove("active");
      index = 0;
      iRef.current.querySelectorAll("img")[index].classList.add("active");
      tRef.current.querySelectorAll("div")[index].classList.add("active");
      nav.current.querySelectorAll("button")[0].classList.add("inactive");
    }
  }, [isTouchDevice]);

  // Trigger on click
  function slideHandler() {
    if (isTouchDevice()) {
      return;
    }
    if (index === 0) {
      direction = "forwards";
    } else if (index === length) {
      direction = "backwards";
    }
    if (direction === "backwards") {
      index--;
      slideLeft();
    } else if (direction === "forwards") {
      index++;
      slideRight();
    }
    if (index === 0) {
      setCursor(cursorImg.forwards);
    } else if (index === length) {
      setCursor(cursorImg.backwards);
    }
  }

  function slideLeft() {
    if (isTouchDevice()) {
      nav.current.querySelectorAll("button")[0].classList.remove("inactive");
      nav.current.querySelectorAll("button")[1].classList.remove("inactive");
      if (index === 1) {
        nav.current.querySelectorAll("button")[0].classList.add("inactive");
      } else {
        nav.current.querySelectorAll("button")[0].classList.remove("inactive");
      }
      if (index === 0) {
        return;
      } else {
        index--;
      }
    }
    const images = iRef.current.querySelectorAll("img");
    const text = tRef.current.querySelectorAll("div");
    images.forEach((o, i) => {
      if (i > index) {
        o.classList.add("hidden");
      }
      o.classList.remove("active");
    });
    text.forEach((i) => {
      i.classList.remove("active");
    });
    text[index].classList.add("active");
    images[index].classList.add("active");
  }

  function slideRight() {
    if (isTouchDevice()) {
      nav.current.querySelectorAll("button")[0].classList.remove("inactive");
      nav.current.querySelectorAll("button")[1].classList.remove("inactive");
      if (index === length - 1) {
        nav.current.querySelectorAll("button")[1].classList.add("inactive");
      } else {
        nav.current.querySelectorAll("button")[1].classList.remove("inactive");
      }
      if (index === length) {
        return;
      } else {
        index++;
      }
    }
    const images = iRef.current.querySelectorAll("img");
    const text = tRef.current.querySelectorAll("div");
    images.forEach((o) => {
      // o.classList.remove("hidden");
      o.classList.remove("active");
    });
    text.forEach((i) => {
      i.classList.remove("active");
    });
    text[index].classList.add("active");
    images[index].classList.add("active");
    images[index].classList.remove("hidden");
  }

  useEffect(() => {
    cRef.current.style.cursor = cursor;
    if (isTouchDevice()) {
      nav.current.classList.add("touch");
    }
  }, [cursor, isTouchDevice]);

  return (
    <section id="client-feedback" className="mg-t-lg">
      <div className="container">
        <div className="client-feedback-title">
          <h2>
            Feedback <br />
            <span>from our clients...</span>
          </h2>
          <div className="reviews">
            <a
              href="https://uk.trustpilot.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                width="164px"
                height="41px"
                src={Trust}
                alt="Trustpilot"
              />
            </a>
            <a
              href="https://g.page/r/CbTsLjDX86tXEBM/review"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                width="121px"
                height="40px"
                src={Google}
                alt="Google"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="container-full">
        <div className="client-feedback" onClick={slideHandler} ref={cRef}>
          <div className="client-feedback-images" ref={iRef}>
            <img loading="lazy" src={D} alt="Dennis" />
            <img loading="lazy" src={I} alt="IMR" />
            <img loading="lazy" src={V} alt="Vetcarehome" />
            <img loading="lazy" src={P} alt="Prestige" />
            <img loading="lazy" src={T} alt="Tough Enough To Care" />
            <img loading="lazy" src={P2} alt="Percys Pet Products" />
          </div>
          <div className="client-feedback-text" ref={tRef}>
            <div>
              <img
                loading="lazy"
                width="204px"
                height="82px"
                className="logo"
                src={D_L}
                alt="Dennis Logo"
              />
              <p className="p-lg">
                "We have recently used Hyphen on the design of our 3 product
                brochures for 2023. From initial discussions to completion they
                'completely got' what we are as a brand, our standing in the
                market place and the vision we had. Nothing was too much trouble
                for them. We are already discussing other projects."
              </p>
              <p>Roger Moore</p>
              <p className="company">Dennis & Howardson Group</p>
            </div>
            <div>
              <img
                loading="lazy"
                width="123px"
                height="42px"
                className="logo"
                src={I_L}
                alt="IMR Logo"
              />
              <p className="p-lg">
                "Hyphen Creative have done various work for me from small
                graphic design jobs to a full website overhaul. The standard of
                work and diligence is a cut above any other design/web agency I
                haveever used and unlike others in the area the work is
                delivered on time and within a sensible budget. I would
                recommend them gladly to anyone else and frequently do."
              </p>
              <p>Bob</p>
              <p className="company">IMR Acoustics</p>
            </div>
            <div>
              <img
                loading="lazy"
                width="169px"
                height="78px"
                className="logo"
                src={V_L}
                alt="VetCareHome Logo"
              />
              <p className="p-lg">
                "Great company, producing great results. Listen to objectives of
                what we want to achieve and then produces stunning results. Very
                personal and friendly service from all members of the team."
              </p>
              <p className="company">VetCare@Home</p>
            </div>
            <div>
              <img
                loading="lazy"
                width="204px"
                height="82px"
                className="logo"
                src={P_L}
                alt="Prestige Logo"
              />
              <p className="p-lg">
                "I have had so much support from these guys for both my
                businesses with my limited knowledge they have supported with
                everything, nothing is too much trouble. 5 star, simply
                brilliant and so professional."
              </p>
              <p>Sue/Director</p>
              <p className="company">Prestige Beauty</p>
            </div>
            <div>
              <img
                loading="lazy"
                width="204px"
                height="82px"
                className="logo"
                src={T_L}
                alt="Tough Enough To Care Logo"
              />
              <p className="p-lg">
                "What an amazing and professional company. The work and support
                they have given to the Tough Enough To Care, mens mental health
                charity, is fantastic."
              </p>
              <p>Alex/Charity Director</p>
              <p className="company">Tough Enough To Care</p>
            </div>
            <div>
              <img
                loading="lazy"
                width="150px"
                height="82px"
                className="logo"
                src={P2_L}
                alt="Percys Logo"
              />
              <p className="p-lg">
                "Hyphen Creative helped my tremendiously with the design,
                creation and set up of my website. Nothing is too much trouble
                and all work was carried out and finished within the agreed
                timescale. I couldn't be happier."
              </p>
              <p>Donna/Director</p>
              <p className="company">Percy's Pet Products</p>
            </div>
          </div>
        </div>
        <div ref={nav} className="client-feedback-nav">
          <button onClick={slideLeft}>
            <img
              width="50px"
              height="50px"
              src={BackwardsCursor}
              alt="Back Arrow"
            />
          </button>
          <button onClick={slideRight}>
            <img
              width="50px"
              height="50px"
              src={ForwardsCursor}
              alt="Forward Arrow"
            />
          </button>
        </div>
      </div>
    </section>
  );
}

export default ClientFeedback;
